import React, { useState } from "react";
import axios from "axios";
import "./ImageUpload.css";

const ImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [endpoint, setEndpoint] = useState("classify-beauty-container");
  const [responseOutput, setResponseOutput] = useState("No response yet...");
  const [loading, setLoading] = useState(false); // New loading state

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      setPreviewUrl(null);
    }
  };

  const handleEndpointChange = (event) => {
    setEndpoint(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("endpoint", endpoint);

    setLoading(true); // Set loading to true when the request starts
    setResponseOutput("Uploading..."); // Optionally set a placeholder message while loading

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ""}/api/upload`,
        formData,
      );
      setResponseOutput(JSON.stringify(response.data, null, 4));
    } catch (error) {
      setResponseOutput(`Error: ${error.message}`);
    } finally {
      setLoading(false); // Set loading to false when the request completes
    }
  };

  return (
    <div className="upload-container">
      <form onSubmit={handleSubmit}>
        <div className="upload-wrapper">
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="Preview"
              className="image-preview"
              style={{ width: "100%", maxHeight: "400px", objectFit: "cover" }}
            />
          ) : (
            <h1>Click to select image</h1>
          )}
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            required
            disabled={loading} // Disable the file input while loading
          />
        </div>
        <label>
          Select API Endpoint:
          <select
            value={endpoint}
            onChange={handleEndpointChange}
            disabled={loading} // Disable the endpoint select while loading
          >
            <option value="classify-beauty-container">Classify Beauty</option>
            <option value="object-recognition-beauty-container">
              Object Recognition
            </option>
          </select>
        </label>
        <button type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Upload"}
        </button>
      </form>

      <h2>Response:</h2>
      <pre>{responseOutput}</pre>
    </div>
  );
};

export default ImageUpload;
